import _app from '@core/modules/theme/pages/_app';

// /**
//  * Import global css
//  * */
import '@core/styles/index.css';
import '@core/styles/mediaquery.css';
import '@core/styles/flexboxgrid.min.css';
import '@core/styles/checkboxtree.css';

import '@core/styles/custom-intro-js.css';

export default _app;
