export const PRIMARY = '#000000';
export const PRIMARY_SOFT = '#B92790';
export const PRIMARY_DARK = '#4D2F82';
export const SECONDARY = '#818181';

export const GRAY_PRIMARY = '#DEDEDE';
export const GRAY_SECONDARY = '#B4B4B4';
export const GRAY_THIRD = '#6E6E6E';
export const GRAY_LIGHT = '#dbdbdb';
export const WHITE = '#FFFFFF';
export const WHITE_IMPORTANT = '#FFFFFF !important';

export const RED = '#E02B27';
export const ORANGE = '#FE5D26';
export const GREEN = '#00D3A1';
export const BLACK = '#414048';

export const PURPLE = '#BE1F93';
export const TABLE_GRAY = '#F3F4FA';
export const TEXT_COLOR = '#68779F';
export const CYAN_BOLD = '#42929D';
export const BLUE_CYAN = '#05AECF';
export const BLUE_SELECT = '#E7F3FF';
export const BLUE_LIGHT = '#3E8BFF';
export const BLUE_BACKGROUND = '#F2F9FF';
export const ORANGE_PRIMARY = '#EE781B';

export const COLOR_INVERT = 'invert(48%) sepia(54%) saturate(402%) hue-rotate(139deg) brightness(96%) contrast(89%)';
export const COLOR_BORDER = '#D5EAFB';
export const COLOR_SWITCH = '#7B9AAF';

export const DARK_ELECTRIC_BLUE = '#536777';
export const PURPLE_NAVY = '#435179';